import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from '../auths/Login';
import Dashboard from '../components/Dashboard';
import ChangePassword from '../components/ChangePassword';

import Protect from './Protect';
import Users from '../components/Users';
import Report from '../components/Report';
import { decodeData } from '../utils/Helper';
import Agents from '../components/Agents';
import Transaction from '../components/Transaction';
import Masters from '../components/Masters';
import Seniors from '../components/Seniors';

const Routers = () => {
  const [userInfo,setUserInfo] = useState<any>({})
  useEffect(()=>{
      const getAuth = localStorage.getItem('six_d_a');
      let decode = getAuth && decodeData(getAuth);
      if(decode){
          setUserInfo(decode)
      };
  },[]);

  return (
    <Router basename="/">
      <Routes>
        <Route path="/login" element={<Login />} /> 

        <Route path="/change-password" element={<Protect><ChangePassword /></Protect>} /> 
        <Route path="/" element={<Protect><Dashboard /></Protect>} /> 
        {/* <Route path="/report" element={<Protect><Report type="users" endPoint="/report"/></Protect>} />  */}
        {
          userInfo?.role_id === 2 &&
          <>
            <Route path="/users/senior" element={<Protect><Seniors title="Seniors" type="seniors" endPoint="/seniors" /></Protect>} /> 
            <Route path="/payment/transaction/seniors" element={<Protect><Transaction type="seniors" endPoint="/seniors/transactions" /></Protect>} /> 
            <Route path="/report" element={<Protect><Report type="seniors" endPoint="/report"/></Protect>} /> 
          </>
        }
        {
          userInfo?.role_id === 3 &&
          <>
            <Route path="/users/master" element={<Protect><Masters title="Masters" type="masters" endPoint="/masters" /></Protect>} />
            <Route path="/report" element={<Protect><Report type="masters" endPoint="/report"/></Protect>} /> 
            <Route path="/payment/transaction/masters" element={<Protect><Transaction type="masters" endPoint="/masters/transactions" /></Protect>} /> 
          </>
        }
        {
          userInfo?.role_id === 4 &&
          <>
            <Route path="/users/user" element={<Protect><Users title="Users" type="users" endPoint="/users" /></Protect>} /> 
            <Route path="/users/agent" element={<Protect><Agents title="Agents" type="agents" endPoint="/agents" /></Protect>} />
            <Route path="/report" element={<Protect><Report type="agents" endPoint="/report"/></Protect>} /> 
            <Route path="/payment/transaction/agents" element={<Protect><Transaction type="agents" endPoint="/agents/transactions" /></Protect>} /> 
            <Route path="/payment/transaction" element={<Protect><Transaction type="users" endPoint="/users/transactions" /></Protect>} /> 
          </>
        }
        {
          userInfo?.role_id === 5 &&
          <>
            <Route path="/users/user" element={<Protect><Users title="Users" type="users" endPoint="/users" /></Protect>} /> 
            <Route path="/report" element={<Protect><Report type="users" endPoint="/report"/></Protect>} /> 
            <Route path="/payment/transaction" element={<Protect><Transaction type="users" endPoint="/users/transactions" /></Protect>} /> 
          </>
        }
        <Route path="*" element={<Protect></Protect>} /> 
      </Routes>
    </Router>
  )
}

export default Routers
