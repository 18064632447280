import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import { ToastContainer } from 'react-toastify'
import { getMethodService, postMethodService } from '../services';
import { CHANGE_PASSWORD_API, LOGIN_API, REPORT_API, WINNER_API } from '../services/constants';
import { useNavigate } from 'react-router-dom';
import { HandleLogout, amountCommasSparated } from '../utils/Helper';
import TableScroll from '../utils/TableScroll';
import TableLoading from '../utils/TableLoading';
import { useQuery } from '@tanstack/react-query';
import { stateObjType } from '../types';
import DateRange from '../utils/DateRange';
import moment from 'moment';
import Pagi from '../utils/Pagi';

const Transaction = ({type,endPoint}:any) => {
  const [isLoading,setIsLoading] = useState(false);
  const [transaction,setTransaction] = useState<any>([]);
  const [startDate,setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate,setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [refresh,setRefresh] = useState(0);
  const [search ,setSearch] = useState("");
  const navigate = useNavigate();

  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  useEffect(()=>{
    setSearch("");
    setRefresh(prev => prev + 1);
  },[type]);
  const fetchTrans = () => getMethodService(`${endPoint}?search=${search}&startDate=${startDate}&endDate=${endDate}&page=${page}`);
  const transQuery = useQuery({ queryKey: [page,refresh], queryFn: () =>{
     if(refresh > 0){
        return fetchTrans();
     }
  }, refetchOnWindowFocus: false});

  useEffect(() => {
        if(transQuery){
            setIsLoading(transQuery.isLoading);
            if(transQuery?.data === 'Unauthenticated.' || transQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
          
            if(transQuery?.data?.status === "success" || transQuery?.data?.success){
                setTransaction(transQuery?.data?.data);
                if(transQuery?.data?.meta){
                    if(transQuery?.data?.meta?.current_page > 0){
                        if(transQuery?.data?.meta?.per_page && transQuery?.data?.meta?.current_page){
                            setFrom((transQuery?.data?.meta.per_page * (transQuery?.data?.meta?.current_page - 1)) + 1 );
                        };
                    };
                    setTotalPage(Math.ceil(transQuery?.data?.meta?.total / transQuery?.data?.meta?.per_page));
                };
            };
        };
  }, [transQuery]);

  const submitHandler = () => {
    setRefresh(prev => prev + 1);
  };

  return (
    <>
        <Template title={`Payment`}>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-end justify-content-between">
                        <div className="col-sm-4">
                            <DateRange setStartDate={setStartDate} setEndDate={setEndDate} />
                        </div>
                        <div className="col-sm-4">
                            <div>
                                <label className="form-label" htmlFor="search">Search</label>
                                <input type="text" value={search} placeholder='Search' id='search' className='form-control' onChange={e => setSearch(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="text-end">
                                <button className='btn btn-dark px-5' onClick={submitHandler}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table text-white w-100 table-bordered table-striped">
                                        <thead>
                                            <tr style={{backgroundColor: 'rgb(70 70 227)'}}>
                                                <th className="p-2 text-center text-light">No</th>
                                                <th className="p-2 text-center text-light">Name</th>
                                                <th className="p-2 text-center text-light">User Name</th>
                                                <th className="p-2 text-center text-light">Type</th>
                                                <th className="p-2 text-center text-light">Points</th>
                                                <th className="p-2 text-center text-light">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading && <TableLoading col={6} />}
                                            {
                                                transaction?.length > 0 ?
                                                transaction?.map((rep:stateObjType,i:number) => 
                                                    <tr style={{verticalAlign: 'middle'}} key={i}>
                                                        <td className="p-2 text-center text-dark">{i + from}</td>
                                                        <td className="p-2 text-center text-dark">{rep?.name ?? '--'}</td>
                                                        <td className="p-2 text-center text-dark">{rep?.username ?? '--'}</td>
                                                        <td className="p-2 text-center text-dark">
                                                            <span className={`badge bg-${rep?.type === 'increase'? 'success': 'danger'}`}>{rep?.type}</span>
                                                        </td>
                                                        <td className="p-2 text-end text-dark">{amountCommasSparated(rep?.points)}</td>
                                                        <td className="p-2 text-center text-dark">{rep?.created_at}</td>
                                                    </tr>
                                                ):
                                                <tr>
                                                    <td className='text-center' colSpan={6}>No found data</td>
                                                </tr>
                                            }
                                        </tbody>
                                        {
                                            transaction?.length > 0 &&
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={4} className='bg-dark text-end text-white'>Total :</td>
                                                    <td className="p-2 text-end text-dark">
                                                        {
                                                            amountCommasSparated(transaction.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.points), 0))
                                                        }
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        }
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
                {totalPage > 1 && (
                    <div className="card-footer">
                        <div className="row justify-content-end">
                            <div>
                                <Pagi
                                    setIsLoading={setIsLoading}
                                    setPage={setPage}
                                    totalPage={totalPage}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Template>
        <ToastContainer />
    </>
  )
}

export default Transaction;
