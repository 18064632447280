import { createSlice } from "@reduxjs/toolkit";

const lotteryDateSlice = createSlice({
    name: "lotteryDate",
    initialState: {
      lotteryDate: {},
    },
    reducers: {
        lotteryDateHandler: (state, action) => {
        state.lotteryDate = action.payload;
        }
    }
});

export const { lotteryDateHandler } = lotteryDateSlice.actions;
export default lotteryDateSlice.reducer;

