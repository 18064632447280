import React, { useState } from 'react';
import CusInput from '../utils/CusInput';
import { postMethodServiceV2 } from '../services';
import { LOGIN_API } from '../services/constants';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { HandleLogout, capitalizeFirstLetter, encodeData } from '../utils/Helper';
import SubmitBtn from '../utils/SubmitBtn';
import { FiDownload } from 'react-icons/fi';
const logo = require("../assets/images/logo/logo.png");
const bgImg = require('../assets/images/background/card-6.svg')

const Login = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [type,setType] = useState('super');
  const [loginCode,setLoginCode] = useState("");
  const [loginPassword,setLoginPassword] = useState("");
  const [loginCodeError,setLoginCodeError] = useState("");
  const [loginPasswordError,setLoginPasswordError] = useState("");
  const navigate = useNavigate();

  let deferredPrompt:any;

  window.addEventListener("beforeinstallprompt", (event) => {
    event.preventDefault();
    deferredPrompt = event;
  });

  const triggerPWAInstallation = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    } else {
        alert('To install this app, open the browser menu and select "Add to Home Screen".')
    }
  };

  const loginSubmitHandler = async (e:React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsLoading(true);
      const data = {
         username: loginCode,
         password: loginPassword
      };
      postMethodServiceV2(`${type}/login`, data, true)
      .then(res => {
        setIsLoading(false);
        if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
            HandleLogout(navigate);
        };

        if(res?.status === "success" || res?.success){
            const saveInfo = {
              token: res?.data?.token,
              role_id: res?.data?.agent_info?.role_id,
              type: type,
            };
            const bcode = encodeData(saveInfo);
            localStorage.setItem("six_d_a", bcode);
            const delay = setTimeout(()=>{
              setLoginCode("");
              setLoginPassword("");
              navigate('/');
              window.location.reload();
            },1000);

            return ()=> {
                clearTimeout(delay);
            }
        };
      })
     
  };

  const loginCodeHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
      setLoginCode(e.target.value);
      setLoginCodeError("");
  };

  const loginPasswordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
      setLoginPassword(e.target.value);
      setLoginPasswordError("");
  };
  

  return (
    <>
      <main id="content" role="main" className="">
        <div className="position-fixed top-0 end-0 start-0 bg-img-start" style={{height: '32rem', backgroundImage: `url(${bgImg.default})`}}>
            <div className="shape shape-bottom zi-1">
              <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
                <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
              </svg>
            </div>
        </div>
        <div className="container py-5 py-sm-7">
          <div className="d-flex justify-content-center mb-5">
            <img className="zi-2 rounded-circle" src={logo} alt="Image Description" style={{width: '8rem', border: '2px solid #FFF',boxShadow: '0 5px 10px 0 rgba(43, 43, 43, .2)'}}/>
          </div>

          <div className="mx-auto" style={{maxWidth: '30rem'}}>
            <div className="card card-lg mb-5">
              {/* <div className='flex justify-content-end'>
                  <button
                      className="bg-primary text-white rounded-md py-1 px-3 flex justify-end items-center gap-2 cursor-pointer max-h-[40px] max-w-[85px]"
                      onClick={() => triggerPWAInstallation()}
                    >
                      App <FiDownload />
                  </button>
              </div> */}
              <div className="card-body">
            
                <form onSubmit={loginSubmitHandler}>
                  <div className="text-center">
                    <div className="mb-5">
                      <h1 className="display-5">Thai 6D | {capitalizeFirstLetter(type)} Login</h1>
                    </div>
                  </div>
                  
                  <div className="row mb-4">

                    <div className="col-sm mb-2 mb-sm-0">
                      <label className="form-control" htmlFor="formControlRadioEg1">
                        <span className="form-check">
                          <input type="radio" className="form-check-input" name="formControlRadioEg" id="formControlRadioEg1" checked={type === "super"} onChange={()=> setType('super')}/>
                          <span className="form-check-label">Super</span>
                        </span>
                      </label>
                    </div>

                    <div className="col-sm mb-2 mb-sm-0">
                      <label className="form-control" htmlFor="formControlRadioEg2">
                        <span className="form-check">
                          <input type="radio" className="form-check-input" name="formControlRadioEg" id="formControlRadioEg2" checked={type === "senior"} onChange={()=> setType('senior')}/>
                          <span className="form-check-label">Senior</span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='row mb-4'>
                    <div className="col-sm mb-2 mb-sm-0">
                      <label className="form-control" htmlFor="formControlRadioEg3">
                        <span className="form-check">
                          <input type="radio" className="form-check-input" name="formControlRadioEg" id="formControlRadioEg3" checked={type === "master"} onChange={()=> setType('master')}/>
                          <span className="form-check-label">Master</span>
                        </span>
                      </label>
                    </div>

                    <div className="col-sm mb-2 mb-sm-0">
                      <label className="form-control" htmlFor="formControlRadioEg4">
                        <span className="form-check">
                          <input type="radio" className="form-check-input" name="formControlRadioEg" id="formControlRadioEg4" checked={type === "agent"} onChange={()=> setType('agent')}/>
                          <span className="form-check-label">Agent</span>
                        </span>
                      </label>
                    </div>
                  </div>

                  <CusInput title="Username" type="text" error={loginCodeError} tabIndex={1} placeholder='Username' name="code" value={loginCode} event={loginCodeHandler}/>
                  <CusInput title="Password" type="password" error={loginPasswordError} tabIndex={0} placeholder='Password' name="password" value={loginPassword} event={loginPasswordHandler}/>
                  <div className="form-check mb-4">
                    <input className="form-check-input" type="checkbox" value="" id="termsCheckbox" />
                    <label className="form-check-label" htmlFor="termsCheckbox">
                      Remember me
                    </label>
                  </div>

                  <div className="d-grid">
                      <SubmitBtn type="submit" isLoading={isLoading} name='Sign in'/>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </>
  )
}

export default Login
