import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

const DateRange = ({setStartDate,setEndDate}:any) => {

  const callbackHandler = (start:any, end:any) => {
    setStartDate(start.format("YYYY-MM-DD"));
    setEndDate(end.format("YYYY-MM-DD"));
  }
  return (
    <div>
      <label className="form-label">Date</label>
      <div className="input-group">
        <span className="input-group-text" id="basic-addon1">
            <i className="bi bi-calendar4-week text-primary"></i>
        </span>
        <DateRangePicker
          onCallback = {callbackHandler}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
    </div>
    </div>
    
  )
}

export default DateRange
