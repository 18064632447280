import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import { ToastContainer } from 'react-toastify'
import { getMethodService, getMethodServiceV2, postMethodService } from '../services';
import { CHANGE_PASSWORD_API, LOGIN_API, REPORT_API, WINNER_API } from '../services/constants';
import { Link, useNavigate } from 'react-router-dom';
import { HandleLogout, amountCommasSparated } from '../utils/Helper';
import TableScroll from '../utils/TableScroll';
import TableLoading from '../utils/TableLoading';
import { useQuery } from '@tanstack/react-query';
import { stateObjType } from '../types';
import moment from 'moment';
import Pagi from '../utils/Pagi';
import Ticket from '../utils/Ticket';
import NumbersModal from '../utils/NumbersModal';

const Report = ({type,endPoint}:any) => {
  const [reportLoading,setReportLoading] = useState(true);
  const [betLoading,setBetLoading] = useState(true);
  const [report,setReport] = useState<any>([]);
  const [dateRange,setDateRange] = useState([]);
  const [date,setDate] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [refresh,setRefresh] = useState(0);
  const [search ,setSearch] = useState("");
  const [downLine,setDownLine] = useState<any>([]);
  const [betSlips,setBetSlips] = useState<any>([]);
  const [roleId,setRoleId] = useState<any>("");
  const navigate = useNavigate();
  const [editData,setEditData] = useState<any>([]);
  const [showTickets, setShowTickets] = useState(false);

  const day = moment().format('DD');
  const sectionDate = (parseInt(day) > 16) ? moment().add(1, 'months').format("MMM 01 YYYY"):moment().format("MMM 16 YYYY");

//   const fetchReport = () => getMethodService(`${endPoint}?history_id=${date}&username=${ ((downLine.length === 1 && roleId === 4) ? '': downLine[0]) ?? ''}&search=${search}&page=${page}`);
  const fetchReport = () => getMethodService(`${endPoint}?history_id=${date}&username=${ (( roleId !== 6 ) && downLine[downLine.length - 1]) ?? ''}&search=${search}&page=${page}`);
  const reportQuery = useQuery({ queryKey: ["repots",downLine,refresh], queryFn: ()=>{
    if (roleId !== 6) {
        return fetchReport();
    };
  }, refetchOnWindowFocus: false});

  const fetchUserBets = () => getMethodService(`/users/six-bets?history_id=${date}&username=${roleId === 6? downLine[downLine.length - 1] : ''}&search=${search}&limit=99999`);
  const userBetsQuery:any = useQuery({ queryKey: ["bets",downLine], queryFn: () => {
    if (roleId === 6) {
        return fetchUserBets();
    };
  }, refetchOnWindowFocus: false});

//   ------- for old date data -------
  const fetchDateRange = () => getMethodServiceV2(`/old-histories`);
  const dateRangeQuery = useQuery({ queryKey: ["oldDate"], queryFn: fetchDateRange, refetchOnWindowFocus: false});

  useEffect(() => {
        if(reportQuery){
            setReportLoading(reportQuery?.isLoading);
            if(reportQuery?.data){
                if(reportQuery?.data === 'Unauthenticated.' || reportQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                    HandleLogout(navigate);
                };
              
                if(reportQuery?.data?.status === "success" || reportQuery?.data?.success){
                    setReport(reportQuery?.data?.data);
                    if(reportQuery?.data?.meta){
                        if(reportQuery?.data?.meta?.current_page > 0){
                            if(reportQuery?.data?.meta?.per_page && reportQuery?.data?.meta?.current_page){
                                setFrom((reportQuery?.data?.meta?.per_page * (reportQuery?.data?.meta?.current_page - 1)) + 1 );
                            };
                        };
                        setTotalPage(Math.ceil(reportQuery?.data?.meta?.total / reportQuery?.data?.meta?.per_page));
                    };
                };
            }
        };
  }, [reportQuery]);

  useEffect(() => {
    if(userBetsQuery){
        setBetLoading(userBetsQuery.isLoading);
        if(userBetsQuery?.data === 'Unauthenticated.' || userBetsQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
            HandleLogout(navigate);
        };
      
        if(userBetsQuery?.data?.status === "success" || userBetsQuery?.data?.success){
            setBetSlips(userBetsQuery?.data?.data);
        };
    };
}, [userBetsQuery]);

  useEffect(() => {
        if(dateRangeQuery){
            if(dateRangeQuery?.data === 'Unauthenticated.' || dateRangeQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
        
            if(dateRangeQuery?.data?.status === "success" || dateRangeQuery?.data?.success){
                setDateRange(dateRangeQuery?.data?.data);
            };
        };
    }, [dateRangeQuery]);

  const submitHandler = () => {
    setRefresh(prev => prev + 1);
  };

  const downLineHandler = (data:any) => {
    if(reportLoading){
        return;
    };
    setRoleId(data?.role_id);
    let toArr = [...downLine];
    toArr.push(data?.username);
    let unique:any = new Set(toArr);
    setDownLine([...unique]);
    setSearch("");
  };

  const downLineBackHandler = () => {
    let toArr = [...downLine];
    setRoleId("");
    toArr.pop();
    setDownLine(toArr);
  };
        

  return (
    <>
        <Template title={`Reports`}>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-end justify-content-between">
                        <div className="col-sm-4">
                            {/* <DateRange setStartDate={setStartDate} setEndDate={setEndDate} /> */}
                            {
                                downLine.length > 0 &&
                                <button className='btn btn-dark py-2 mb-2' onClick={downLineBackHandler}>Back</button>
                            }
                            <div className='mt-3 mt-sm-0'>
                                <label className="form-label" htmlFor="search">Date</label>
                                <select name="" id="" className='form-control' value={date} onChange={e => setDate(e.target.value)}>
                                    <option value="">{sectionDate}</option>
                                    {
                                        dateRange.length > 0 &&
                                        dateRange.map((el:any) => 
                                            <option value={el?.id} key={el?.id}>
                                                {el?.closing_time}
                                            </option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className='mt-3 mt-sm-0'>
                                <label className="form-label" htmlFor="search">Search</label>
                                <input type="text" value={search} placeholder='Search' id='search' className='form-control' onChange={e => setSearch(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="text-end mt-3 mt-sm-0">
                                <button className='btn btn-primary px-5' onClick={submitHandler}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        {
                            roleId === 6 ?
                            <div className="col-sm-12">
                                <TableScroll>
                                    <div>
                                        <table className="table text-white w-100 table-bordered table-striped">
                                            <thead>
                                                <tr style={{backgroundColor: 'rgb(70 70 227)'}}>
                                                    <th className="p-2 text-center text-light">No</th>
                                                    <th className="p-2 text-center text-light">User Name</th>
                                                    <th className="p-2 text-center text-light">Number</th>
                                                    <th className="p-2 text-center text-light">Point</th>
                                                    <th className="p-2 text-center text-light">Win Amount</th>
                                                    <th className="p-2 text-center text-light">Rate</th>
                                                    <th className="p-2 text-center text-light">Win Amount ( MMK )</th>
                                                    {/* <th className="p-2 text-center text-light">Bar Code</th> */}
                                                    <th className="p-2 text-center text-light">Date Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {betLoading && <TableLoading col={9} />}
                                                {
                                                    betSlips?.length > 0 ?
                                                    betSlips?.map((rep:stateObjType,i:number) => 
                                                        <tr style={{verticalAlign: 'middle'}} key={i}>
                                                            <td className="p-2 text-center text-dark">{i + from}</td>
                                                            <td className="p-2 text-center text-dark">{rep?.username}</td>
                                                            <td className="p-2 text-center text-dark">
                                                                <button className="p-1 btn btn-info btn-sm" onClick={() => {setShowTickets(true);setEditData(rep)}}>click me</button>
                                                            </td>
                                                            <td className="p-2 text-end text-dark">{amountCommasSparated(rep?.amount) ?? 0}</td>
                                                            <td className="p-2 text-end text-dark">{amountCommasSparated(rep?.win_amount) ?? 0}</td>
                                                            <td className="p-2 text-center text-dark">{rep?.rate ?? 0}</td>
                                                            <td className="p-2 text-end text-dark">{amountCommasSparated(rep?.win_amount_mmk) ?? 0}</td>
                                                            <td className="p-2 text-center text-dark">{rep?.date_time}</td>
                                                        </tr>
                                                    ):
                                                    <tr>
                                                        <td className='text-center' colSpan={9}>No found data</td>
                                                    </tr>
                                                }
                                            </tbody>
                                            {
                                                betSlips?.length > 0 &&
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={3} className='bg-dark text-end text-white'>Total :</td>
                                                        <td className="p-2 text-end text-dark">
                                                            {
                                                                amountCommasSparated(betSlips.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.amount), 0)) ?? 0
                                                            }
                                                        </td>
                                                        <td className="p-2 text-end text-dark">
                                                            {
                                                                amountCommasSparated(betSlips.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.win_amount), 0)) ?? 0
                                                            }
                                                        </td>
                                                        <td></td>
                                                        <td className="p-2 text-end text-dark">
                                                            {
                                                                amountCommasSparated(betSlips.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.win_amount_mmk), 0)) ?? 0
                                                            }
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            }
                                        </table>
                                    </div>
                                </TableScroll>
                            </div>
                            :
                            <div className="col-sm-12">
                                <TableScroll>
                                    <div>
                                        <table className="table text-white w-100 table-bordered table-striped">
                                            <thead>
                                                <tr style={{backgroundColor: 'rgb(70 70 227)'}}>
                                                    <th className="p-2 text-center text-light">No</th>
                                                    <th className="p-2 text-center text-light">User Name</th>
                                                    <th className="p-2 text-center text-light">Purchase Points</th>
                                                    <th className="p-2 text-center text-light">Win Amount</th>
                                                    <th className="p-2 text-center text-light">Rate</th>
                                                    <th className="p-2 text-center text-light">Win Amount ( MMK )</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reportLoading && <TableLoading col={6} />}
                                                {
                                                    report?.length > 0 ?
                                                    report?.map((rep:stateObjType,i:number) => 
                                                        <tr style={{verticalAlign: 'middle'}} key={i}>
                                                            <td className="p-2 text-center text-dark">{i + from}</td>
                                                            <td className="p-2 text-center text-dark">
                                                                <Link to="#" aria-disabled className='text-decoration-underline' onClick={() => downLineHandler(rep)}>
                                                                    {
                                                                        rep?.username
                                                                    }
                                                                    {
                                                                        rep?.role_id === 2 &&
                                                                        <span className='badge bg-danger ml-2'>Super</span>
                                                                    }
                                                                    {
                                                                        rep?.role_id === 3 &&
                                                                        <span className='badge bg-primary ml-2'>Senior</span>
                                                                    }
                                                                    {
                                                                        rep?.role_id === 4 &&
                                                                        <span className='badge bg-info ml-2'>Master</span>
                                                                    }
                                                                    {
                                                                        rep?.role_id === 5 &&
                                                                        <span className='badge bg-warning ml-2'>Agent</span>
                                                                    }
                                                                    {
                                                                        rep?.role_id === 6 &&
                                                                        <span className='badge bg-success ml-2'>User</span>
                                                                    }
                                                                </Link>
                                                            </td>
                                                            <td className="p-2 text-end text-dark">{amountCommasSparated(rep?.purchase_points) ?? 0}</td>
                                                            <td className="p-2 text-end text-dark">{amountCommasSparated(rep?.win_amount)?? 0}</td>
                                                            <td className="p-2 text-center text-dark">{rep?.rate ?? 0}</td>
                                                            <td className="p-2 text-end text-dark">{amountCommasSparated(rep?.win_amount_mmk) ?? 0}</td>
                                                        </tr>
                                                    ):
                                                    <tr>
                                                        <td className='text-center' colSpan={6}>No found data</td>
                                                    </tr>
                                                }
                                            </tbody>
                                            {
                                                report?.length > 0 &&
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={2} className='bg-dark text-end text-white'>Total :</td>
                                                        <td className="p-2 text-end text-dark">
                                                            {
                                                                amountCommasSparated(report.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.purchase_points), 0)) ?? 0
                                                            }
                                                        </td>
                                                        <td className="p-2 text-end text-dark">
                                                            {
                                                                amountCommasSparated(report.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.win_amount), 0)) ?? 0
                                                            }
                                                        </td>
                                                        <td></td>
                                                        <td className="p-2 text-end text-dark">
                                                            {
                                                                amountCommasSparated(report.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.win_amount_mmk), 0)) ?? 0
                                                            } MMK
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            }
                                        </table>
                                    </div>
                                </TableScroll>
                            </div>
                        }

                       {/* here** */}
                    </div>
                </div>
                {totalPage > 1 && (
                    <div className="card-footer">
                        <div className="row justify-content-end">
                            <div>
                                <Pagi
                                    setIsLoading={setReportLoading}
                                    setPage={setPage}
                                    totalPage={totalPage}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Template>
        <ToastContainer />
        {
        showTickets && (
            <NumbersModal
                title= {`Ticket - ${editData?.name} ( ${editData?.id} )`}
                onClose={() => setShowTickets(false)}
            >
                <Ticket data={editData} />
                
            </NumbersModal>
        )
      }
    </>
  )
}

export default Report
