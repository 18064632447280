import React from 'react'
import classes from '../assets/styles/Loading.module.css';
const Loading = () => {
  return (
    <div className={classes.loadingContainer}>
      <div className={`${classes['lds-facebook']}`}><div></div><div></div><div></div></div>
    </div>
  )
}

export default Loading
