import React, { useRef } from "react";
import moment from "moment-timezone";
import { FiDownload, FiX } from "react-icons/fi";
import classes from "../assets/styles/RandomCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import Barcode from "react-barcode";
import * as htmlToImage from "html-to-image";
import { isDownloadHandler } from "../store/slices/isDownloadSlice";

export default function RandomCard({
  number = "",
  event,
  new_event,
  bet_date = "",
  name,
  lottery_date = "",
  lottery_win_date = "",
  image_name = "",
  setRounded = "rounded-md",
  barcode = "",
  download = false,
  select = false,
  select_event,
  select_numbers
}) {
  const { lotteryDate } = useSelector((state) => state.lotteryDate);
  const { isDownload } = useSelector((state) => state.isDownload);
  const dispatch = useDispatch();
  const currentMonthTextual = moment(lotteryDate?.closing_time)
    .format("MMMM")
    .toLocaleLowerCase();
  const randomCardImage = require(`../assets/images/sixd/${
    image_name === "" ? currentMonthTextual : image_name
  }.png`);
  const nums = [
    { 1: "ONE" },
    { 2: "TWO" },
    { 3: "THR" },
    { 4: "FOU" },
    { 5: "FIV" },
    { 6: "SIX" },
    { 7: "SEV" },
    { 8: "EIG" },
    { 9: "NIN" },
    { 0: "ZER" },
  ];
  const convertNumberToString = (number, initial) => {
    let result = [];
    number.split("").map((digit) => {
      const find = initial.find((num) => num[digit]);
      if (find) result.push({ num: digit, string: find[digit] });
    });
    return result;
  };

  const { user_info } = useSelector((state) => state.userInfo);

  const targetEl = useRef(null);
  const downLoadImage = async () => {
    dispatch(isDownloadHandler(true));
    const dataUrl = await htmlToImage.toPng(targetEl.current);

    // download image
    const link = document.createElement("a");
    link.download = `${
      name || user_info?.name
    }_SixD_E-Ticket_${lottery_date}.png`;
    link.href = dataUrl;
    link.click();
    setTimeout(() => {
      dispatch(isDownloadHandler(false));
    }, 3000);
  };

  const url = window.location.pathname;
  const tempArr = ["--", "--", "--", "--", "--", "--"];

  return (
    <label
      htmlFor={`checkbox${number}`}
      className="relative block animate-scaleIn w-full max-w-[460px] px-3 mb-3 mx-auto"
      ref={targetEl}
    >
      <div className="relative">
        {select && (
          <div className="absolute top-[8.5%] md:top-[5%] translate-y-[-5%] right-[2px] md:right-[1%] md:translate-x-[-1%]">
            <label
              className={`${classes.container}`}
              htmlFor={`checkbox${number}`}
            >
              <input type="checkbox" id={`checkbox${number}`} onClick={e => select_event(number)} checked={(select_numbers.length > 0 && select_numbers.find(el => `${el.number}` === `${number}`) ? true : false )} />
              <div
                className={classes.checkmark}
                htmlFor={`checkbox${number}`}
              ></div>
            </label>
          </div>
        )}
        <img
          className={`shadow-md ${setRounded} border-[.5px] border-slate-300 ${
            bet_date !== "" ? "border border-slate-300" : ""
          }`}
          src={randomCardImage}
          alt={currentMonthTextual}
        />
        {number && url === "/choose-number" && (
          <div
            className="absolute top-[0] right-[0] rounded-md w-[25px] h-[25px]  border border-white flex justify-center items-center bg-red-500 hover:bg-red-600 text-white text-lg cursor-pointer"
            onClick={() => event(number)}
          >
            <FiX />
          </div>
        )}
      </div>
      <div
        className={`absolute flex justify-center gap-[2px] text-slate-700 octin-font text-center ${classes.lottery_num}`}
      >
        {number
          ? convertNumberToString(number, nums)?.map((el, i) => (
              <div key={el.num + i} className={classes.num_card}>
                <p className={classes.number}>{el.num}</p>
                <p className={`translate-y-[-5px] ${classes.label}`}>
                  {el.string}
                </p>
              </div>
            ))
          : tempArr.map((el, i) => (
              <div
                key={i}
                className={`${classes.num_card} translate-y-[6px] cursor-pointer`}
                title="ဂဏန်းထည့်မည်။"
                onClick={new_event}
              >
                <p className={classes.number}>{el}</p>
              </div>
            ))}
      </div>
      {download && !isDownload && (
        <div
          className="absolute w-[30px] h-[30px] top-0 right-3 bg-blue-600 text-white text-xl flex justify-center items-center rounded-md z-50 cursor-pointer"
          onClick={() => downLoadImage()}
        >
          <FiDownload />
        </div>
      )}
      {lottery_win_date && (
        <div
          className={`absolute top-[35%] translate-y-[-35%] w-[130px] text-center octin-font text-slate-700  left-[75%] translate-x-[-75%] text-[17px]`}
        >
          {moment(lottery_win_date, "DD MM YYYY").format("DD MMM YYYY")}
        </div>
      )}
      {lottery_date !== "" && (
        <div
          className={`absolute top-[35%] translate-y-[-35%] w-[130px] text-center octin-font text-slate-700  left-[75%] translate-x-[-75%] text-[17px]`}
        >
          {lottery_date || ""}
        </div>
      )}
      {barcode && (
        <div
          className={`absolute top-[100%] translate-y-[-100%] text-center md:left-[77.5%] left-[82%] md:translate-x-[-77.5%] translate-x-[-82%] scale-1 md:scale-125`}
        >
          <Barcode
            value={barcode}
            height={18}
            width={0.9}
            fontSize={6}
            format="codabar"
            background="none"
          />
        </div>
      )}
      {number && (
        <div
          className={`absolute top-[50%] translate-y-[-50%] min-w-[100px] md:min-w-[130px] text-white bg-blue-600 px-1 py-[1px] tracking-tight text-center whitespace-nowrap capitalize ${!name || name.length > 18 ? 'left-[80%] translate-x-[-80%] text-[6px]' : 'left-[74%] translate-x-[-74%]'}`}
          style={{ WebkitTextStroke: ".25px blue" }}
        >
          {name || "ဘတ်၆သန်းဆုကြီးပေါက်ပါစေ"}
        </div>
      )}
      {bet_date && (
        <div
          className={`absolute top-[65%] translate-y-[-65%] octin-font text-slate-700 left-[75%] translate-x-[-75%] text-[14px] text-center whitespace-nowrap`}
        >
          {bet_date}
        </div>
      )}
    </label>
  );
}
