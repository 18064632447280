import { createSlice } from "@reduxjs/toolkit";

const isDownload = createSlice({
  name: "isDownload",
  initialState: {
    isDownload: false,
  },
  reducers: {
    isDownloadHandler: (state, action) => {
      state.isDownload = action.payload;
    },
  },
});

export const { isDownloadHandler } = isDownload.actions;
export default isDownload.reducer;
