import React, { useEffect, useState } from "react";
import { FiChevronDown, FiChevronUp, FiDownload } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import * as htmlToImage from "html-to-image";
import moment from "moment-timezone";
import TenTicketHistory from "./TenTicketHistory";
import { isDownloadHandler } from "../store/slices/isDownloadSlice";

export default function Ticket({
  data = {},
}) {
  const { isDownload } = useSelector(state => state.isDownload);
  const dispatch = useDispatch();
  const { user_info } = useSelector((state) => state.userInfo);
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    let temp_array = [];
    for (let i = 0; i < data.number.length / 10; i++) {
      const tickets = data.number.slice(i * 10, (i + 1) * 10)
      temp_array.push(tickets)
    }
    setTickets(temp_array);
  }, [data]);

  const current_date = moment.tz("Asia/Yangon").format("ll");

  const downLoadImage = async (target_url, no) => {
    const target = document.getElementById(target_url);
    const dataUrl = await htmlToImage.toPng(target);
    const link = document.createElement("a");
    link.download = `${data.name || user_info?.name}_SixD_E-Ticket_${current_date}_${no}.png`;
    link.href = dataUrl;
    link.click();
  };

  const downloadAllImages = () => {
    dispatch(isDownloadHandler(true));
    tickets.forEach((url, i) => downLoadImage(`history_ticket_${data.id}_${i}_${data.name}`, i))
    setTimeout(() => {
      dispatch(isDownloadHandler(false));
    }, (tickets.length * 1000) + 4000 );
  };

  const getMonth = (input, long = false) => {
    if (long) {
      return moment(input, 'DD-MM-YYYY, hh:mm:ss A').format('MMMM').toLocaleLowerCase();
    }
    return moment(input, 'DD-MM-YYYY, hh:mm:ss A').format('MMM')
  }

  const getNextMonth = (input, long = false) => {
    const bet_day = (moment(input, 'DD-MM-YYYY, hh:mm:ss A').format('DD'))
    if (long) {
      if (+bet_day < 16) {
        return moment(input, 'DD-MM-YYYY, hh:mm:ss A').format('MMMM').toLocaleLowerCase();
      } else {
        return moment(input, 'DD-MM-YYYY, hh:mm:ss A').add(1, 'month').format('MMMM').toLocaleLowerCase();
      }
    } else {
      if (+bet_day < 16) {
        return moment(input, 'DD-MM-YYYY, hh:mm:ss A').format('MMM')
      } else {
        return moment(input, 'DD-MM-YYYY, hh:mm:ss A').add(1, 'month').format('MMM')
      }
    }
  }

  const getYear = input => {
    const bet_month = moment(input, 'DD-MM-YYYY, hh:mm:ss A').format('MM')
    const bet_day = (moment(input, 'DD-MM-YYYY, hh:mm:ss A').format('DD'))
    if (+bet_month === 12) {
      if (+bet_day < 16) {
        return moment(input, 'DD-MM-YYYY, hh:mm:ss A').format('YYYY')
      } else {
        return moment(input, 'DD-MM-YYYY, hh:mm:ss A').add(1, 'month').format('YYYY')
      }
    } else {
      return moment(input, 'DD-MM-YYYY, hh:mm:ss A').format('YYYY')
    }
  }

  return (
    <div className="rounded-lg relative overflow-hidden mb-3">
      <div
        className="flex justify-end items-center"
      >
        <p className="text-slate-900">
            <div
              className={`mb-3 text-[14px] px-2 py-1 bg-blue-600 text-white text-lg flex justify-center items-center rounded-md z-50 cursor-pointer ${isDownload ? 'invisible' : ''}`}
              onClick={() => downloadAllImages(tickets)}
            >
              <span className="me-2">Download</span> <FiDownload />
            </div>
        </p>
      </div>
      <div
        className="details-section"
      >
        <div className="h-full overflow-hidden overflow-y-auto">
        {
          tickets?.length > 0 &&
          tickets?.map((num, i) =>
          <div key={`history_ticket_${i}_${data?.name}`} id={`history_ticket_${data.id}_${i}_${data.name}`}>
          <TenTicketHistory 
            bet_date={data?.date_time}
            name={data?.name}
            number={num}
            numbers={num}
            barcode={data?.barcode?.slice(i * 10, (i + 1) * 10)}
            image_name={data?.time === 'one' ? getMonth(data?.date_time, true) : getNextMonth(data?.date_time, true)}
            lottery_date={data?.time === 'one' ? `16 ${getMonth(data?.date_time)} ${getYear(data?.date_time)}` : `01 ${getNextMonth(data?.date_time)} ${getYear(data?.date_time)}`}
            download={true}
            />
          </div>
          )
        }
        </div>
      </div>
    </div>
  );
}