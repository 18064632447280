import { configureStore } from '@reduxjs/toolkit'
import unitControlSlite from './slices/unitControlSlite'
import userInfoSlice from './slices/userInfoSlice'
import userIdSlice from './slices/userIdSlice'
import isDownloadSlice from './slices/isDownloadSlice'
import lotteryDateSlice from './slices/lotteryDateSlice'
export const store = configureStore({
    reducer: {
        unitController: unitControlSlite,
        userInfo: userInfoSlice,
        userId: userIdSlice,
        lotteryDate: lotteryDateSlice,
        isDownload: isDownloadSlice
    },
  })
