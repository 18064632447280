import React, { useRef } from "react";
import { useSelector } from 'react-redux';
import hundred_mmk from "../assets/images/icons/hundred_mmk.png";
import logo_img from "../assets/images/logo/logo.png";
import RandomCard from "./RandomCard";

export default function TenTicketHistory({ numbers, lottery_date, bet_date, name, barcode, image_name, download = false, event }) {
  const { isDownload } = useSelector(state => state.isDownload);
  const { user_info } = useSelector(state => state.userInfo);

  const targetEl = useRef(null);

  return (
    <>
    {
      numbers.length > 1 ? 
      <div className="bg-[#054fd7] mx-1 py-4 rounded-lg mb-3 overflow-hidden relative" ref={targetEl}>
        <div className="w-[120px] h-[120px] bg-[#054fd7] absolute left-0 bottom-0 rounded-bl-lg z-[1] rounded-tr-[200px] flex justify-start items-center">
          <img className="w-[65px] ms-3 mt-3" src={logo_img} alt="Logo" />
        </div>
        <div className="w-[100px] md:w-[100px] absolute bottom-[10px] left-[75px] z-[1]">
          <img className="w-full" src={hundred_mmk} alt="1 Hundred MMK" />
        </div>
        {numbers?.length > 0 &&
          numbers?.map((num, i) => (
            <div
              key={`ticket_i_${i}`}
              className={`w-full overflow-hidden ${
                i === numbers.length - 1 ? "h-full" :'h-[35px] md:h-[40px]'
              }`}
              id={`single_e_ticket_${num}_${i}`}
            >
              <RandomCard
                number={num}
                lottery_date={lottery_date}
                bet_date={bet_date}
                name={name}
                barcode={bet_date && barcode[i]}
                image_name={image_name}
                setRounded={ `${i === numbers.length - 1 ? "rounded-b-md" : ""} ${
                  i === 0 ? "rounded-t-md" : ""
                }`}
                event={event}
              />
            </div>
          ))}
      </div> :
      <div id={`single_e_ticket_${numbers[0]}_${0}`}>
        <RandomCard
        number={numbers[0]}
        lottery_date={lottery_date}
        bet_date={bet_date}
        name={name}
        barcode={bet_date && barcode[0]}
        image_name={image_name}
        setRounded={"rounded-md"}
        event={event}
      />
      </div>
    }
    </>
  );
}