import { SYSTEM_ERROR } from "../config";
import axios from "axios";
import { decodeData, errorToaster, successToaster } from "../utils/Helper";
import Swal from "sweetalert2";
let domain = process.env.REACT_APP_DOMAIN;

export const getMethodService = (endPoint, data,isShow_toast = false) => {
    const getAuth = localStorage.getItem('six_d_a');
    let decode = getAuth && decodeData(getAuth);

    return new Promise((resolve, reject) => {
 
        try {
            axios({
                method: "get",
                url: `${domain}api/${decode?.type}${endPoint}`,
                headers: {
                    Authorization: `Bearer ${decode?.token}`,
                },
                params: data ?? null,
                timeout: 1000 * 60 * 3,
            })
                .then((res) => {
                    if(res){
                        if(isShow_toast){
                            successToaster(res?.data?.message);
                        };
                        resolve(res.data);
                    }
                    
                })
                .catch((error) => {
                    if(error?.response?.data?.message === "Unauthorised"){
                        return Swal.fire(error?.response?.data?.error, "", "error");
                    };
                    errorToaster(error?.response?.data?.message);
                    resolve(error?.response?.data?.message);
                    reject(error);
                });
        } catch (error) {
            reject("Something wring!");
        }
    });
};

export const getMethodServiceV2 = (endPoint, data,isShow_toast = false) => {
    const getAuth = localStorage.getItem('six_d_a');
    let decode = getAuth && decodeData(getAuth);

    return new Promise((resolve, reject) => {
 
        try {
            axios({
                method: "get",
                url: `${domain}api${endPoint}`,
                headers: {
                    Authorization: `Bearer ${decode?.token}`,
                },
                params: data ?? null,
                timeout: 1000 * 60 * 3,
            })
                .then((res) => {
                    if(res){
                        if(isShow_toast){
                            successToaster(res?.data?.message);
                        };
                        resolve(res.data);
                    }
                    
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    resolve(error?.response?.data?.message);
                    reject(error);
                });
        } catch (error) {
            reject("Something wring!");
        }
    });
};

export const postMethodService =  (endPoint,data,isShow_toast = false) => {
    const getAuth = localStorage.getItem('six_d_a');
    let decode = getAuth && decodeData(getAuth);
    return new Promise(async (resolve, reject) => {
        try {
            axios
                .post(`${domain}api/${decode ? decode?.type: ''}${endPoint}`, data, {
                    headers: {
                        Authorization: `Bearer ${decode?.token}`,
                        Accept: "application/json",
                    },
                    timeout: 1000 * 60 * 3,
                })
                .then((res) => {
                    if(isShow_toast){
                        successToaster(res?.data?.message);
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    resolve(error?.response?.data?.message);
                    reject(error);
                });

        }
        catch(err){
            reject("Something wring!")
        };
    });
     
};

export const postMethodServiceV2 =  (endPoint,data,isShow_toast = false) => {
    const getAuth = localStorage.getItem('six_d_a');
    let decode = getAuth && decodeData(getAuth);
    return new Promise(async (resolve, reject) => {
        try {
            axios
                .post(`${domain}api/${endPoint}`, data, {
                    headers: {
                        Authorization: `Bearer ${decode?.token}`,
                        Accept: "application/json",
                    },
                    timeout: 1000 * 60 * 3,
                })
                .then((res) => {
                    if(isShow_toast){
                        successToaster(res?.data?.message);
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    resolve(error?.response?.data?.message);
                    reject(error);
                });

        }
        catch(err){
            reject("Something wring!")
        };
    });
     
}

export const putMethodService = (api, data, isShow_toast = false) => {
    const getAuth = localStorage.getItem('six_d_a');
    let decode = getAuth && decodeData(getAuth);
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(api, data, {
                    headers: {
                        Authorization: `Bearer ${decode?.token}`,
                        Accept: "application/json",
                    },
                    timeout: 1000 * 60 * 3,
                })
                .then((res) => {
                    if(isShow_toast){
                        successToaster(res?.data?.message);
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    resolve(error?.response?.data?.message);
                    reject(error);
                });
        } catch (error) {
            reject("Something wring!")
        }
    });
};

export const deleteMethodService = (api, isShow_toast = false) => {
    const getAuth = localStorage.getItem('six_d_a');
    let decode = getAuth && decodeData(getAuth);
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(api, {
                    headers: {
                        Authorization: `Bearer ${decode?.token}`,
                        Accept: "application/json",
                    },
                    timeout: 1000 * 60 * 3,
                })
                .then((res) => {
                    if(isShow_toast){
                        successToaster(res?.data?.message);
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    resolve(error?.response?.data?.message);
                    reject(error);
                });
        } catch (error) {
            reject("Something wring!")
        }
    });
};


//for image upload
export const putApiDataService = (api, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(api, data, {
                    headers: {
                        Authorization: `${localStorage.getItem("token")}`,
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                    timeout: 1000 * 60 * 3,
                })
                .then((res) => {
                    successToaster(res.data.message);
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    if (
                        error.response.status === 401 ||
                        error.response.data.message === "Unauthenticated."
                    ) {
                        // HandleLogout();
                        return;
                    }
                    reject(error);
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const patchApiDataService = (api, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .patch(api, data, {
                    headers: {
                        Authorization: `${localStorage.getItem("token")}`,
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                    timeout: 1000 * 60 * 3,
                })
                .then((res) => {
                    successToaster(res.data.message);
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    if (
                        error.response.status === 401 ||
                        error.response.data.message === "Unauthenticated."
                    ) {
                        // HandleLogout();
                        return;
                    }
                    reject(error);
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const postApiImageUploadService = (api, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(api, data, {
                    headers: {
                        Authorization: `${localStorage.getItem("token")}`,
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                    timeout: 1000 * 60 * 3,
                })
                .then((res) => {
                    successToaster(res.data.message);
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    if (
                        error.response.status === 401 ||
                        error.response.data.message === "Unauthenticated."
                    ) {
                        return;
                    }
                    reject(error);
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deleteApiDataService = (api, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(api, {
                    headers: {
                        Authorization: `${localStorage.getItem("token")}`,
                        Accept: "application/json",
                    },
                    timeout: 1000 * 60 * 3,
                    data: data,
                })
                .then((res) => {
                    successToaster(res.data.message);
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    if (
                        error.response.status === 401 ||
                        error.response.data.message === "Unauthenticated."
                    ) {
                        // HandleLogout();
                        return;
                    }
                    reject(error);
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
