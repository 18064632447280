import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Template from '../utils/Template'
import { HandleLogout, amountCommasSparated } from '../utils/Helper';
import { getMethodService, getMethodServiceV2, postMethodService } from '../services';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_END_POINT, TURNOVER_END_POINT } from '../services/constants';
import { stateObjType } from '../types';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import CountUp from 'react-countup';
import moment from 'moment';
import Loading from '../utils/Loading';

const Dashboard = () => {
    const [isLoading,setIsLoading] = useState(true);
    const [data, setData] = useState<stateObjType>({});
    // const [turnOver, setTurnOver] = useState<stateObjType>({});
    const [turnOver, setTurnOver] = useState<stateObjType>([]);
    const [dateRange,setDateRange] = useState([]);
    const {user_info} = useSelector((state:any) => state?.userInfo);
    const [date,setDate] = useState("");

    const navigate = useNavigate();

    const fetchDashboardCount = () => getMethodService(DASHBOARD_END_POINT);
    const dashboardQuery = useQuery({ queryKey: ['downlinecount'], queryFn: fetchDashboardCount, refetchOnWindowFocus: false});

    // const fetchTurnOver = () => getMethodService(`${TURNOVER_END_POINT}?history_id=${date}`);
    // const turnOverQuery = useQuery({ queryKey: ['turnover',date], queryFn: fetchTurnOver, refetchOnWindowFocus: false});
    // /api/{role}/report
    const fetchReport = () => getMethodService(`/report?history_id=${date}`);
    const reportQuery = useQuery({ queryKey: ["repots",user_info,date], queryFn: () => user_info?.role_id && fetchReport(), refetchOnWindowFocus: false});

    const fetchDateRange = () => getMethodServiceV2(`/old-histories`);
    const dateRangeQuery = useQuery({ queryKey: ["oldDate"], queryFn: fetchDateRange, refetchOnWindowFocus: false});

    const day = moment().format('DD');
    const sectionDate = (parseInt(day) > 16) ? moment().add(1, 'months').format("MMM 01 YYYY"):moment().format("MMM 16 YYYY");

    useEffect(() => {
        if(dashboardQuery){
            if(dashboardQuery?.data === 'Unauthenticated.' || dashboardQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(dashboardQuery?.data?.status === "success" || dashboardQuery?.data?.success){
                setData(dashboardQuery?.data?.data);
            };
        };
    }, [dashboardQuery]);

    // useEffect(() => {
    //     if(turnOverQuery){
    //         setIsLoading(turnOverQuery.isLoading);
    //         if(turnOverQuery?.data === 'Unauthenticated.' || turnOverQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
    //             HandleLogout(navigate);
    //         };
    //         if(turnOverQuery?.data?.status === "success" || turnOverQuery?.data?.success){
    //             setTurnOver(turnOverQuery?.data?.data);
    //         };
    //     };
    // }, [turnOverQuery]);
    useEffect(() => {
        if(reportQuery){
            setIsLoading(reportQuery.isLoading);
            if(reportQuery?.data === 'Unauthenticated.' || reportQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
          
            if(reportQuery?.data?.status === "success" || reportQuery?.data?.success){
                setTurnOver(reportQuery?.data?.data);
            };
        };
    }, [reportQuery]);

    useEffect(() => {
        if(dateRangeQuery){
            if(dateRangeQuery?.data === 'Unauthenticated.' || dateRangeQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(dateRangeQuery?.data?.status === "success" || dateRangeQuery?.data?.success){
                setDateRange(dateRangeQuery?.data?.data);
            };
        };
    }, [dateRangeQuery]);

  return (
    <>
       <Template title="Dashboard">
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row align-items-end justify-content-between">
                        <div className="col-sm-4">
                            <div className='mt-3 mt-sm-0'>
                                <label className="form-label" htmlFor="search">Date</label>
                                <select name="" id="" className='form-control' value={date} onChange={e => setDate(e.target.value)}>
                                    <option value="">{sectionDate}</option>
                                    {
                                        dateRange.length > 0 &&
                                        dateRange.map((el:any) => 
                                            <option value={el?.id} key={el?.id}>
                                                {el?.closing_time}
                                            </option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                            <a className="card card-hover-shadow h-100" href="#">
                                <div className="card-body">
                                    <h6 className="card-subtitle">Purchase Points</h6>

                                    <div className="row align-items-center gx-2 mb-1">
                                        <div className="col-6">
                                            <h2 className="card-title text-inherit">
                                                {/* <CountUp end={amountCommasSparated(turnOver?.purchase_points) ?? 0} /> */}
                                                {
                                                    amountCommasSparated(turnOver.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.purchase_points), 0)) ?? 0
                                                }
                                            </h2>
                                        </div>
                                        <div className="col-6">
                                            <div className="chartjs-custom" style={{height: '3rem'}}/>
                                        </div>
                                    </div>
                                    <span className="badge bg-soft-success text-success">
                                        <i className="bi-graph-up"></i> %
                                    </span>
                                    <span className="text-body fs-6 ms-1">
                                        {/* <CountUp end={amountCommasSparated(turnOver?.purchase_points) ?? 0} /> Pt */}
                                        {
                                            amountCommasSparated(turnOver.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.purchase_points), 0)) ?? 0
                                        } Pt
                                    </span>
                                </div>
                            </a>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                            <a className="card card-hover-shadow h-100" href="#">
                                <div className="card-body">
                                    <h6 className="card-subtitle">Win Amount</h6>

                                    <div className="row align-items-center gx-2 mb-1">
                                        <div className="col-6">
                                            <h2 className="card-title text-inherit">
                                                {/* <CountUp end={amountCommasSparated(turnOver?.win_amount) ?? 0} /> */}
                                                {
                                                    amountCommasSparated(turnOver.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.win_amount), 0)) ?? 0
                                                }
                                            </h2>
                                        </div>
                                        <div className="col-6">
                                            <div className="chartjs-custom" style={{height: '3rem'}}/>
                                        </div>
                                    </div>
                                    <span className="badge bg-soft-success text-success">
                                        <i className="bi-graph-up"></i>%
                                    </span>
                                    <span className="text-body fs-6 ms-1">
                                        {/* <CountUp end={amountCommasSparated(turnOver?.win_amount) ?? 0} /> B */} 
                                        {
                                            amountCommasSparated(turnOver.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.win_amount), 0)) ?? 0
                                        } B
                                    </span>
                                </div>
                            </a>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                            <a className="card card-hover-shadow h-100" href="#">
                                <div className="card-body">
                                    <h6 className="card-subtitle">Win Amount ( MMK )</h6>

                                    <div className="row align-items-center gx-2 mb-1">
                                        <div className="col-6">
                                            <h2 className="card-title text-inherit">
                                                {/* <CountUp end={amountCommasSparated(turnOver?.win_amount_mmk) ?? 0} /> */}
                                                {
                                                    amountCommasSparated(turnOver.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.win_amount_mmk), 0)) ?? 0
                                                }
                                            </h2>
                                        </div>
                                        <div className="col-6">
                                            <div className="chartjs-custom" style={{height: '3rem'}}/>
                                        </div>
                                    </div>
                                    <span className="badge bg-soft-success text-success">
                                        <i className="bi-graph-up"></i> %
                                    </span>
                                    <span className="text-body fs-6 ms-1">
                                        {/* <CountUp end={amountCommasSparated(turnOver?.win_amount_mmk) ?? 0} /> MMK */}
                                        {
                                            amountCommasSparated(turnOver.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.win_amount_mmk), 0)) ?? 0
                                        } MMK
                                    </span>
                                </div>
                            </a>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                            <a className="card card-hover-shadow h-100" href="#">
                                <div className="card-body">
                                    <h6 className="card-subtitle">Rate</h6>

                                    <div className="row align-items-center gx-2 mb-1">
                                        <div className="col-6">
                                            <h2 className="card-title text-inherit">
                                                {/* <CountUp end={amountCommasSparated(turnOver[0]?.rate) ?? 0} /> */}
                                                {
                                                    amountCommasSparated(turnOver[0]?.rate) ?? 0
                                                }
                                            </h2>
                                        </div>
                                        <div className="col-6">
                                            <div className="chartjs-custom" style={{height: '3rem'}}/>
                                        </div>
                                    </div>
                                    <span className="badge bg-soft-success text-success">
                                        <i className="bi-graph-up"></i> %
                                    </span>
                                    <span className="text-body fs-6 ms-1">
                                        {/* <CountUp end={amountCommasSparated(turnOver[0]?.rate) ?? 0} /> */}
                                        {
                                            amountCommasSparated(turnOver[0]?.rate) ?? 0
                                        }
                                    </span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div>
                                <table className="table text-white w-100 table-bordered table-striped">
                                    <tbody>
                                        <tr style={{verticalAlign: 'middle'}}>
                                            <td className="p-2 text-center text-dark">Username</td>
                                            <td className="p-2 text-end text-dark">{user_info?.username ?? '--'}</td>
                                        </tr>
                                        <tr style={{verticalAlign: 'middle'}}>
                                            <td className="p-2 text-center text-dark">Name</td>
                                            <td className="p-2 text-end text-dark">{user_info?.name ?? '--'}</td>
                                        </tr>
                                        <tr style={{verticalAlign: 'middle'}}>
                                            <td className="p-2 text-center text-dark">Role</td>
                                            <td className="p-2 text-end text-dark">
                                                {(user_info?.role_id === 1 && 'Admin')}
                                                {(user_info?.role_id === 2 && 'Super')}
                                                {(user_info?.role_id === 3 && 'Senior')}
                                                {(user_info?.role_id === 4 && 'Master')}
                                                {(user_info?.role_id === 5 && 'Agent')}
                                            </td>
                                        </tr>
                                        <tr style={{verticalAlign: 'middle'}}>
                                            <td className="p-2 text-center text-dark">Users</td>
                                            <td className="p-2 text-end text-dark">{data?.total_user ?? '--'}</td>
                                        </tr>
                                        <tr style={{verticalAlign: 'middle'}}>
                                            <td className="p-2 text-center text-dark">Active Users</td>
                                            <td className="p-2 text-end text-dark">{data?.active_user ?? '--'}</td>
                                        </tr>
                                        <tr style={{verticalAlign: 'middle'}}>
                                            <td className="p-2 text-center text-dark">Users Points</td>
                                            <td className="p-2 text-end text-dark">{amountCommasSparated(data?.total_point) ?? '--'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
            
       </Template>
       <ToastContainer />
       {
          isLoading && <Loading />
       }
    </>
  )
}

export default Dashboard
