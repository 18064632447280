import React from "react";
const {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
  } = require("reactstrap");

function NumbersModal(props: any) {

  return (
    <Modal isOpen={true} toggle={props.onClose}>
      <ModalHeader toggle={props.onClose}>
         <div style={{fontSize: '18px'}}>{props?.title}</div>
      </ModalHeader>
      <ModalBody>
        {
          props.children
        }
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-danger" onClick={props.onClose}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default NumbersModal;
