import React, { useEffect, useState } from 'react'
import { UsersTypes, stateObjType } from '../types'
import Template from '../utils/Template'
import TableScroll from '../utils/TableScroll'
import TableLoading from '../utils/TableLoading'
import { getMethodService, postMethodService } from '../services'
import { useQuery } from '@tanstack/react-query'
import { HandleLogout, amountCommasSparated } from '../utils/Helper'
import { useNavigate } from 'react-router-dom'
import { DOWNLINE_CHANGE_PASSWORD_API, SENIORS_EDIT_API, SENIOR_BALANCE_TRANS_API, USERS_API } from '../services/constants'
import { ToastContainer } from 'react-toastify'
import ModalBox from '../utils/ModalBox';
import { Form, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import Pagi from '../utils/Pagi';
import CusInput from '../utils/CusInput';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

const Seniors = ({title,type,endPoint}:UsersTypes) => {
  const [isLoading,setIsLoading] = useState(false);
  const [users,setUsers] = useState([]);
  const [username,setUsername] = useState("");
  const [name,setName] = useState("");
  const {user_info} = useSelector((state:any) => state?.userInfo);
  const [showChangePasswordModal,setShowChangePasswordModal] = useState(false);
  const [showCreateModal,setShowCreateModal] = useState(false);
  const [showPayModal,setShowPayModal] = useState(false);
  const [showBanModal,setShowBanModal] = useState(false);
  const [amount,setAmount] = useState("");
  const [search ,setSearch] = useState("");
  const [editData,setEditData] = useState<any>(null);
  const [newPass,setNewPass] = useState("");
  const [conPass,setConPass] = useState("");
  const [newPasswordError,setNewPasswordError] = useState("");
  const [conPasswordError,setConPasswordError] = useState("");
  const [banVal,setBanVal] = useState<any>("ban_for_next_7_days");
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [refreshKey, setRefreshKey] = useState(0);

  const navigate = useNavigate();

  const fetchUsers = () => getMethodService(`${endPoint}?search=${search}&page=${page}`);
  const usersQuery = useQuery({ queryKey: [type, page,refreshKey], queryFn: fetchUsers, refetchOnWindowFocus: false});

    useEffect(() => {
        if(usersQuery){
            setIsLoading(usersQuery.isLoading);
            if(usersQuery?.data === 'Unauthenticated.' || usersQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            
            if(usersQuery?.data?.status === "success" || usersQuery?.data?.success){
                setUsers(usersQuery?.data?.data);

                if(usersQuery?.data?.meta){
                    if(usersQuery?.data?.meta?.current_page > 0){
                        if(usersQuery?.data?.meta?.per_page && usersQuery?.data?.meta?.current_page){
                            setFrom((usersQuery?.data?.meta.per_page * (usersQuery?.data?.meta?.current_page - 1)) + 1 );
                        };
                    };
                    setTotalPage(Math.ceil(usersQuery?.data?.meta?.total / usersQuery?.data?.meta?.per_page));
                };
            };
        };
    }, [usersQuery]);

    useEffect(()=>{
        setName(editData?.name);
        setAmount(editData?.points > 0 ? `-${editData?.points}`:editData?.points);
    },[editData]);

    const newPasswordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value.length === 9){
            return;
        };
        setNewPass(e.target.value);
        setNewPasswordError("");
    };

    const conPasswordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value.length === 9){
            return;
        };
        setConPass(e.target.value);
        setConPasswordError("");
    };

    const masterCreateHandler = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const userData = {
            name,
            username,
            password: newPass,
            'confirm-password': conPass
        };
        setIsLoading(true);
        postMethodService(endPoint, userData, true)
        .then(res => {
            setIsLoading(false);
            if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(res?.status === "success" || res?.success){
                setShowCreateModal(false);
                setRefreshKey(prevKey => prevKey + 1);
            };
        });
    };
    
    const userEditHandler = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const userData = {
            senior_id: editData?.id,
            name,
            password: newPass,
            'confirm-password': conPass
        };
       
        if(editData){
            setIsLoading(true);
            postMethodService(endPoint, userData, true)
            .then(res => {
                setIsLoading(false);
                if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                    HandleLogout(navigate);
                };
                if(res?.status === "success" || res?.success){
                    setShowChangePasswordModal(false);
                    setRefreshKey(prevKey => prevKey + 1);
                };
            });
        }
    };

    const pointHandler = () => {
        if(editData?.id){
            const data = {
                senior_id: editData?.id,
                points: amount
            };

            setIsLoading(true);
            postMethodService(`${endPoint}/payment-store`, data, true)
            .then(res => {
                setIsLoading(false);
                if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                    HandleLogout(navigate);
                };
                if(res?.status === "success" || res?.success){
                    setShowPayModal(false);
                    setRefreshKey(prevKey => prevKey + 1);
                };
            });
        }
    };

    const banHandler = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const banData = {
            banned_till : banVal
        };
        if(editData){
            setIsLoading(true);
            postMethodService(`${endPoint}/${editData?.id}/ban`, banData, true)
            .then(res => {
                setIsLoading(false);
                if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                    HandleLogout(navigate);
                };
                if(res?.status === "success" || res?.success){
                    setShowBanModal(false);
                    setRefreshKey(prevKey => prevKey + 1);
                };
            });
        }
    };

    const unBanHandler = (user:any) => {
        Swal.fire({
            title: "Are you sure?",
            text: `Unban ${user?.name}`,
            icon: "question",
            showDenyButton: true,
            confirmButtonText: "Save",
            denyButtonText: "Cancel",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
                const banData = {};
                if(user){
                    setIsLoading(true);
                    postMethodService(`${endPoint}/${user?.id}/ban`, banData, true)
                    .then(res => {
                        setIsLoading(false);
                        if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                            HandleLogout(navigate);
                        };
                        if(res?.status === "success" || res?.success){
                            Swal.fire("Success Unban", "", "success");
                            setRefreshKey(prevKey => prevKey + 1);
                        };
                    });
                };
            } else if (result.isDenied) {
              Swal.fire("Cancel Unban", "", "error");
            }
          });
    };

    const submitHandler = () => {
        setRefreshKey(prev => prev + 1);
      };
  return (
    <>
      <Template title={title}>
            <div className="d-flex justify-content-end">
                <button className='btn btn-primary mb-3 px-5' onClick={()=> {
                    setShowCreateModal(true);
                    setUsername("");
                    setName("");
                    setEditData(null);
                    setNewPass("");
                    setConPass("");
                    setNewPasswordError("");
                    setConPasswordError("");
                }}>Create</button>
            </div>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-sm-5">
                            <h4 className="card-title mb-2 mb-sm-0">
                                {title} lists
                            </h4>
                        </div>
                        <div className="col-sm-7">
                            <div className='d-flex justify-content-end align-items-end'>
                                <div>
                                    <label className="form-label" htmlFor="search">Search</label>
                                    <input type="text" placeholder='Search' id='search' className='form-control rounded-0' onChange={e => setSearch(e.target.value)}/>
                                </div>
                                <div className="text-end">
                                    <button className='btn btn-dark px-3 rounded-0' onClick={submitHandler}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table text-white w-100 table-bordered table-striped">
                                        <thead>
                                            <tr style={{backgroundColor: 'rgb(70 70 227)'}}>
                                                <th className="p-2 text-center text-light">No</th>
                                                <th className="p-2 text-center text-light">Name</th>
                                                <th className="p-2 text-center text-light">User Name</th>
                                                <th className="p-2 text-center text-light">Points</th>
                                                <th className="p-2 text-center text-light">Registered Date</th>
                                                <th className="p-2 text-center text-light">Unban Date</th>
                                                <th className="p-2 text-center text-light">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading && <TableLoading col={22} />}
                                            {
                                                users.length > 0 ?
                                                users.map((user:stateObjType,i: number) =>
                                                    <tr style={{verticalAlign: 'middle'}} key={i}>
                                                        <td className="p-2 text-center text-dark">{i + from}</td>
                                                        <td className="p-2 text-center text-dark">{user?.name ?? "-"}</td>
                                                        <td className="p-2 text-center text-dark">{user?.username ?? '-'}</td>
                                                        <td className="p-2 text-end text-dark">{amountCommasSparated(user?.points) ?? '-'}</td>
                                                        <td className="p-2 text-center text-dark">{user?.created_at}</td>
                                                        <td className="p-2 text-center text-dark">
                                                            {
                                                                user?.banned_till == 0 ?
                                                                <span className='badge bg-danger'>Permanent Ban</span>:
                                                                user?.banned_till ?? '--'
                                                            }
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            <button className='btn btn-outline-primary py-1 me-2' onClick={() => {
                                                                setShowPayModal(true);
                                                                setEditData(user);
                                                            }}>
                                                                Pay
                                                            </button>
                                                            <button className='btn btn-outline-success py-1 me-2' onClick={() => {
                                                                setShowChangePasswordModal(true);
                                                                setEditData(user);
                                                                setNewPass("");
                                                                setConPass("");
                                                                setNewPasswordError("");
                                                                setConPasswordError("");
                                                            }}>
                                                                Edit
                                                            </button>
                                                            {
                                                                user?.banned_till ?
                                                                <button className='btn btn-outline-danger py-1' onClick={() => {
                                                                    unBanHandler(user)
                                                                }}>
                                                                    Unban
                                                                </button>
                                                                :
                                                                <button className='btn btn-outline-warning py-1' onClick={() => {
                                                                    setShowBanModal(true);
                                                                    setEditData(user);
                                                                }}>
                                                                    Ban
                                                                </button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td className='text-center' colSpan={22}>No found data</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
                {totalPage > 1 && (
                    <div className="card-footer">
                        <div className="row justify-content-end">
                            <div>
                                <Pagi
                                    setIsLoading={setIsLoading}
                                    setPage={setPage}
                                    totalPage={totalPage}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
      </Template>
      <ToastContainer />
      {
        showBanModal && (
            <ModalBox
                title= "Ban Senior"
                onClose={() => setShowBanModal(false)}
                onSubmit={banHandler}
                isLoading={isLoading}
            >
                <form>
                    <FormGroup>
                        <Label htmlFor="selectBanStatus">Type</Label>
                        <Input
                            id="selectBanStatus"
                            name="select"
                            type="select"
                            onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setBanVal(e.target.value)}
                            value={banVal}
                        >
                            <option value="ban_for_next_7_days">
                                Ban 7 Days
                            </option>
                            <option value="ban_for_next_14_days">
                                Ban 14 Days
                            </option>
                            <option value="ban_permanently">
                                Ban Permanently
                            </option>
                        </Input>
                    </FormGroup>
                </form>
            </ModalBox>
        )
      }
      {
        showCreateModal && (
            <ModalBox
                title= "Create Senior"
                onClose={() => setShowCreateModal(false)}
                onSubmit={masterCreateHandler}
                isLoading={isLoading}
            >
                <form>
                    <FormGroup>
                        <Label htmlFor="amount">User Name</Label>
                        <InputGroup>
                            <InputGroupText className='bg-light text-dark'>
                              {user_info?.username}
                            </InputGroupText>
                            <Input type="text" placeholder="User Name" value={username} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setUsername(e.target.value)}/>
                        </InputGroup>
                    </FormGroup>
                    
                    <FormGroup>
                        <Label htmlFor="amount">Name</Label>
                        <Input type="text" placeholder='Name' value={name} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setName(e.target.value)}/>
                    </FormGroup>
                    <CusInput title="Password" type="password" error={newPasswordError} tabIndex={1} placeholder="ဂဏန်း ၈ လုံးသာလို့ထည့်ပေးပါ" name="newPassowrd" value={newPass} event={newPasswordHandler}/>
                    <CusInput title="Confirm password" type="password" error={conPasswordError} tabIndex={2} placeholder="ဂဏန်း ၈ လုံးသာလို့ထည့်ပေးပါ" name="conPassowrd" value={conPass} event={conPasswordHandler}/>
                </form>
            </ModalBox>
        )
      }

      {
        showPayModal && (
            <ModalBox
                title= "Point"
                onClose={() => setShowPayModal(false)}
                onSubmit={pointHandler}
                isLoading={isLoading}
            >
                <form>
                    <FormGroup>
                        <Label htmlFor="amount">Amount</Label>
                        <Input type="text" value={amount} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setAmount(e.target.value)}/>
                    </FormGroup>
                </form>
            </ModalBox>
        )
      }

      {
        showChangePasswordModal && (
            <ModalBox
                title= "Edit Master"
                onClose={() => setShowChangePasswordModal(false)}
                onSubmit={userEditHandler}
                isLoading={isLoading}
            >
                <form>
                    <FormGroup>
                        <Label htmlFor="amount">User Name</Label>
                        <Input value={editData?.username} disabled/>
                    </FormGroup>
                    
                    <FormGroup>
                        <Label htmlFor="amount">Name</Label>
                        <Input type="text" placeholder='Name' value={name} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setName(e.target.value)}/>
                    </FormGroup>
                    <CusInput title="New password" type="password" error={newPasswordError} tabIndex={1} placeholder="ဂဏန်း ၈ လုံးသာလို့ထည့်ပေးပါ" name="newPassowrd" value={newPass} event={newPasswordHandler}/>
                    <CusInput title="Confirm password" type="password" error={conPasswordError} tabIndex={2} placeholder="ဂဏန်း ၈ လုံးသာလို့ထည့်ပေးပါ" name="conPassowrd" value={conPass} event={conPasswordHandler}/>
                </form>
            </ModalBox>
        )
      }
    </>
  )
  
}

export default Seniors;
