import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { decodeData } from '../utils/Helper';
import { useDispatch } from 'react-redux';
import { userIdHandler } from '../store/slices/userIdSlice';
const sidebarLogo = require('../assets/images/logo/dashboard_light_logo.png')

const Sidebar = () => {
  const url = window.location.pathname;
  const params = useParams();
  const [userInfo,setUserInfo] = useState<any>({})
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const start_date_v2 = params?.start_date && `${params.start_date}`;
  const end_date_v2 = params?.end_date && `${moment(params.end_date).add(0,'days').format('YYYY-MM-DD')} 10:28`;
  const activeLink = `/stock-manage/member/betting-list/${params.id}/${params.status}/${start_date_v2}/${end_date_v2}`;
  const replaceActiveLink = activeLink.split(' ').join('%20');

  const reportActivLink = `/report/win-lose-detail/${params.id}/${params.status}/${params.start_date}/${params.end_date}`;
  const replaceReportActiveLink = reportActivLink.split(' ').join('%20');

  const reportAuserActivLink = `/report/win-lose-detail-a-user/${params.id}/${params.status}/${params.start_date}/${params.end_date}`;
  const replaceReportAuserActiveLink = reportAuserActivLink.split(' ').join('%20');

    useEffect(()=>{
        const getAuth = localStorage.getItem('six_d_a');
        let decode = getAuth && decodeData(getAuth);
        if(decode){
            setUserInfo(decode)
        };
    },[]);

  const bodyClass = document.querySelector('.navbar-vertical-aside-closed-mode ');
  const logoutHandler = () => {
        const token = localStorage.getItem("six_d_a");
        if(token){
            const delay = setTimeout(()=>{
                localStorage.removeItem("six_d_a")
                navigate('/login');
                window.location.reload();
              },1000);
  
              return ()=> {
                  clearTimeout(delay);
              };
        };
  };

  return (
    <aside className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered" style={{backgroundColor: 'rgb(1 1 60)'}}>
        <div className="navbar-vertical-container">
            <div className="navbar-vertical-footer-offset">
                 {/* Logo */}
                 <Link className="navbar-brand justify-content-center" to="/" aria-label="Front">
                    <img className="rounded-circle" src={sidebarLogo} alt="Logo" style={{width: '50px', marginTop: '15px',border: '2px solid #FFF',boxShadow: '0 5px 10px 0 rgba(43, 43, 43, .2)'}}/>
                 </Link>
                 {/* End Logo */}

                 {/* Navbar Vertical Toggle */}
                 <button type="button" className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler">
                    <i className="bi-arrow-bar-left navbar-toggler-short-align" data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>' data-bs-toggle="tooltip" data-bs-placement="right" title="Collapse"></i>
                    <i className="bi-arrow-bar-right navbar-toggler-full-align" data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>' data-bs-toggle="tooltip" data-bs-placement="right" title="Expand"></i>
                </button>
                {/* End Navbar Vertical Toggle */}
                <div className="navbar-vertical-content">
                    <div id="navbarVerticalMenu" className="nav nav-pills nav-vertical card-navbar-nav">
                       
                        <div className="nav-item">
                            <Link className={`nav-link ${url === '/' && 'active'}`} to="/" data-placement="left">
                                <i className="bi-house-door nav-icon"></i>
                                <span className="nav-link-title">ပင်မစာမျက်နှာ</span>
                            </Link>
                        </div>

                        <div className="nav-item">
                            <Link className={`nav-link ${url === '/report' && 'active'}`} to="/report" data-placement="left">
                                <i className="bi bi-card-checklist nav-icon"></i>
                                <span className="nav-link-title">မှတ်တမ်းများ</span>
                            </Link>
                        </div>

                        {/* {
                            userInfo?.role_id === 4 &&
                            <div className="nav-item">
                                <Link className={`nav-link ${url === '/report/agents' && 'active'}`} to="/report/agents" data-placement="left">
                                    <i className="bi bi-card-checklist nav-icon"></i>
                                    <span className="nav-link-title">မှတ်တမ်းများ</span>
                                </Link>
                            </div>
                        }
                        {
                            userInfo?.role_id === 5 &&
                            <div className="nav-item">
                                <Link className={`nav-link ${url === '/report' && 'active'}`} to="/report" data-placement="left">
                                    <i className="bi bi-card-checklist nav-icon"></i>
                                    <span className="nav-link-title">မှတ်တမ်းများ</span>
                                </Link>
                            </div>
                        } */}
                        {
                            userInfo?.role_id === 2 &&
                            <>
                                <div className="nav-item">
                                    <Link className="nav-link dropdown-toggle" to="#navbarVerticalMenuDashboards_account_manage" role="button"
                                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_account_manage"
                                        aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_account_manage">
                                        <i className="bi bi-people-fill nav-icon"></i>
                                        <span className="nav-link-title">အကောင့် စီမံခန့်ခွဲခြင်း</span>
                                    </Link>

                                    <div id="navbarVerticalMenuDashboards_account_manage" className={`nav-collapse collapse
                                    ${((url === '/users/senior')
                                        ) && 'show'} 
                                    `}
                                        data-bs-parent="#navbarVerticalMenuAccount">
                                        <Link className={`nav-link ${url === '/users/senior'  && 'active'}`} to="/users/senior">စီနီယာများ</Link>
                                    </div>
                                </div>
                            </>
                        }
                        

                        {
                            userInfo?.role_id === 3 &&
                            <>
                                <div className="nav-item">
                                    <Link className="nav-link dropdown-toggle" to="#navbarVerticalMenuDashboards_account_manage" role="button"
                                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_account_manage"
                                        aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_account_manage">
                                        <i className="bi bi-people-fill nav-icon"></i>
                                        <span className="nav-link-title">အကောင့် စီမံခန့်ခွဲခြင်း</span>
                                    </Link>

                                    <div id="navbarVerticalMenuDashboards_account_manage" className={`nav-collapse collapse
                                    ${((url === '/users/master') 
                                        ) && 'show'} 
                                    `}
                                        data-bs-parent="#navbarVerticalMenuAccount">
                                        <Link className={`nav-link ${url === '/users/master'  && 'active'}`} to="/users/master">မာစတာများ</Link>
                                    </div>
                                </div>
                            </>
                        }

                        {
                            userInfo?.role_id === 4 &&
                            <>
                                <div className="nav-item">
                                    <Link className="nav-link dropdown-toggle" to="#navbarVerticalMenuDashboards_account_manage" role="button"
                                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_account_manage"
                                        aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_account_manage">
                                        <i className="bi bi-people-fill nav-icon"></i>
                                        <span className="nav-link-title">အကောင့် စီမံခန့်ခွဲခြင်း</span>
                                    </Link>

                                    <div id="navbarVerticalMenuDashboards_account_manage" className={`nav-collapse collapse
                                    ${((url === '/users/user') ||
                                       (url === '/users/agent')
                                        ) && 'show'} 
                                    `}
                                        data-bs-parent="#navbarVerticalMenuAccount">
                                        <Link className={`nav-link ${url === '/users/agent'  && 'active'}`} to="/users/agent">အေးဂျင့်များ</Link>
                                        <Link className={`nav-link ${url === '/users/user'  && 'active'}`} to="/users/user">ယူဆာများ</Link>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            userInfo?.role_id === 5 &&
                            <>
                                <div className="nav-item">
                                    <Link className="nav-link dropdown-toggle" to="#navbarVerticalMenuDashboards_account_manage" role="button"
                                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_account_manage"
                                        aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_account_manage">
                                        <i className="bi bi-people-fill nav-icon"></i>
                                        <span className="nav-link-title">အကောင့် စီမံခန့်ခွဲခြင်း</span>
                                    </Link>

                                    <div id="navbarVerticalMenuDashboards_account_manage" className={`nav-collapse collapse
                                    ${((url === '/users/user')
                                        ) && 'show'} 
                                    `}
                                        data-bs-parent="#navbarVerticalMenuAccount">
                                        <Link className={`nav-link ${url === '/users/user'  && 'active'}`} to="/users/user">ယူဆာများ</Link>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            userInfo?.role_id === 2 &&
                            <>
                                <div className="nav-item">
                                    <Link className="nav-link dropdown-toggle" to="#navbarVerticalMenuDashboards_users" role="button"
                                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_users"
                                        aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_users">
                                        <i className="bi bi-card-checklist nav-icon"></i>
                                        <span className="nav-link-title">ထည့်/ထုတ် မှတ်တမ်းများ</span>
                                    </Link>

                                    <div id="navbarVerticalMenuDashboards_users" className={`nav-collapse collapse
                                    ${((url === '/payment/transaction') 
                                        ) && 'show'} 
                                    `}
                                        data-bs-parent="#navbarVerticalMenuAccount">
                                        <Link className={`nav-link ${url === '/payment/transaction/seniors'  && 'active'}`} to="/payment/transaction/seniors">စီနီယာများ</Link>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            userInfo?.role_id === 3 &&
                            <>
                                <div className="nav-item">
                                    <Link className="nav-link dropdown-toggle" to="#navbarVerticalMenuDashboards_users" role="button"
                                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_users"
                                        aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_users">
                                        <i className="bi bi-card-checklist nav-icon"></i>
                                        <span className="nav-link-title">ထည့်/ထုတ် မှတ်တမ်းများ</span>
                                    </Link>

                                    <div id="navbarVerticalMenuDashboards_users" className={`nav-collapse collapse
                                    ${((url === '/payment/transaction/masters') 
                                        ) && 'show'} 
                                    `}
                                        data-bs-parent="#navbarVerticalMenuAccount">
                                        <Link className={`nav-link ${url === '/payment/transaction/masters'  && 'active'}`} to="/payment/transaction/masters">မာစတာများ</Link>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            userInfo?.role_id === 4 &&
                            <>
                                <div className="nav-item">
                                    <Link className="nav-link dropdown-toggle" to="#navbarVerticalMenuDashboards_users" role="button"
                                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_users"
                                        aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_users">
                                        <i className="bi bi-card-checklist nav-icon"></i>
                                        <span className="nav-link-title">ထည့်/ထုတ် မှတ်တမ်းများ</span>
                                    </Link>

                                    <div id="navbarVerticalMenuDashboards_users" className={`nav-collapse collapse
                                    ${((url === '/payment/transaction') ||
                                       (url === '/payment/transaction/agents')
                                        ) && 'show'} 
                                    `}
                                        data-bs-parent="#navbarVerticalMenuAccount">
                                        <Link className={`nav-link ${url === '/payment/transaction/agents'  && 'active'}`} to="/payment/transaction/agents">အေးဂျင့်များ</Link>
                                        <Link className={`nav-link ${url === '/payment/transaction'  && 'active'}`} to="/payment/transaction">ယူဆာများ</Link>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            userInfo?.role_id === 5 &&
                            <>
                                <div className="nav-item">
                                    <Link className="nav-link dropdown-toggle" to="#navbarVerticalMenuDashboards_users" role="button"
                                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_users"
                                        aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_users">
                                        <i className="bi bi-card-checklist nav-icon"></i>
                                        <span className="nav-link-title">ထည့်/ထုတ် မှတ်တမ်းများ</span>
                                    </Link>

                                    <div id="navbarVerticalMenuDashboards_users" className={`nav-collapse collapse
                                    ${((url === '/payment/transaction')
                                        ) && 'show'} 
                                    `}
                                        data-bs-parent="#navbarVerticalMenuAccount">
                                        <Link className={`nav-link ${url === '/payment/transaction'  && 'active'}`} to="/payment/transaction">ယူဆာများ</Link>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="nav-item">
                            <Link className={`nav-link ${url === '/change-password' && 'active'}`} to="/change-password" data-placement="left">
                                <i className="bi-unlock nav-icon"></i>
                                <span className="nav-link-title">စကားဝှက်ပြောင်း</span>
                            </Link>
                        </div>
                        
                        <span className="mt-4 dropdown-header">Logout</span>
                        <small className="bi-three-dots nav-subtitle-replacer"></small>
                        <div className="nav-item">
                            <Link className='nav-link' to="" style={{cursor: 'pointer'}} data-placement="left" onClick={logoutHandler}>
                                <i className="bi bi-box-arrow-right nav-icon"></i>
                                <span className="nav-link-title">ထွက်မည်</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </aside>
      
  )
}

export default Sidebar
